"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionsSelector = exports.errorAuthMessageSelector = exports.isLoggedInSelector = exports.oidsInstanceSelector = exports.loginSelector = void 0;
var reselect_1 = require("reselect");
var selector_1 = require("../selector");
exports.loginSelector = (0, reselect_1.createSelector)(selector_1.authStoreSelector, function (state) { return state.login; });
exports.oidsInstanceSelector = (0, reselect_1.createSelector)(exports.loginSelector, function (state) { return state.OIDC_Instance; });
exports.isLoggedInSelector = (0, reselect_1.createSelector)(exports.loginSelector, function (state) { return state.isLoggedIn; });
exports.errorAuthMessageSelector = (0, reselect_1.createSelector)(exports.loginSelector, function (state) { return state.error; });
exports.permissionsSelector = (0, reselect_1.createSelector)(exports.loginSelector, function (state) { return state.permissions; });
