"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthOIDService = void 0;
/* TODO: fix */
/* eslint-disable @typescript-eslint/no-explicit-any */
var oidc_client_1 = require("oidc-client");
var AuthOIDService = /** @class */ (function () {
    function AuthOIDService(_a) {
        var authority = _a.authority, client_id = _a.client_id, client_secret = _a.client_secret, redirect_uri = _a.redirect_uri, post_logout_redirect_uri = _a.post_logout_redirect_uri;
        this.userManager = new oidc_client_1.UserManager({});
        var settings = {
            authority: authority,
            client_id: client_id,
            client_secret: client_secret,
            redirect_uri: redirect_uri,
            post_logout_redirect_uri: post_logout_redirect_uri,
            response_type: "token",
            scope: "scoring_api",
            filterProtocolClaims: true,
            loadUserInfo: true,
        };
        this.userManager = new oidc_client_1.UserManager(settings);
    }
    AuthOIDService.prototype.getHashesMap = function (targetWindow) {
        if (targetWindow === void 0) { targetWindow = window.top; }
        var chunks = targetWindow === null || targetWindow === void 0 ? void 0 : targetWindow.location.hash.substr(1).split("&");
        if (chunks)
            return chunks.reduce(function (acc, chunk) {
                var _a;
                var chunkSplitted = chunk.split("=");
                var key = chunkSplitted[0];
                return __assign((_a = {}, _a[key] = chunkSplitted[1], _a), acc);
            }, {});
    };
    AuthOIDService.prototype.getUser = function () {
        return this.userManager.getUser();
    };
    AuthOIDService.prototype.signinCallback = function () {
        return this.userManager.signinRedirectCallback();
    };
    AuthOIDService.prototype.signin = function () {
        return this.userManager.signinRedirect();
    };
    AuthOIDService.prototype.renewToken = function () {
        return this.userManager.signinSilent();
    };
    AuthOIDService.prototype.signout = function () {
        return this.userManager.signoutRedirect();
    };
    AuthOIDService.prototype.clearState = function () {
        return this.userManager.clearStaleState();
    };
    return AuthOIDService;
}());
exports.AuthOIDService = AuthOIDService;
// const authService = new AuthService();
